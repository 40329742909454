import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import { FunctionComponent } from "react";
import background from "../assets/bg.jpg";
import logo from "../assets/favicon.png";
import profile from "../assets/p2.jpg";

export const Home: FunctionComponent<{}> = () => {
  return (
    <div className="">
      <div className="w-full flex items-center justify-between px-10 bg-white sticky ">
        <div className="flex justify-center items-center gap-4">
          <img src={logo} alt="" className="w-24 h-24 rounded-full object-contain" />
          <div>
            <h1
              style={{ fontFamily: "'Dancing Script', cursive" }}
              className="text-secondary-normal text-4xl hidden md:block"
            >
              Naturopathe Sète
            </h1>
          </div>
        </div>
        <div className="w-fit flex gap-8 text-secondary-dark">
          <a href="tel:+33783677554">
            <PhoneIcon height={24} width={24} />
          </a>
          <a href="mailto:carosete@free.fr">
            <MailIcon height={24} width={24} />
          </a>
        </div>
      </div>
      <div
        className="w-full flex bg-cover md:py-32  justify-center items-center bg-center  md:h-fit h-screen"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="flex justify-center items-center  w-fit flex-col md:gap-5 gap-10 px-5 max-w-full">
          <div
            style={{ fontFamily: "'Dancing Script', cursive" }}
            className="text-secondary-normal text-[7rem] text-center leading-[120px] "
          >
            Caroline Perrin
          </div>
          <div className="text-white text-xl font-bold text-center">NATUROPATHE - SOPHROLOGUE CERTIFIÉE RNCP</div>
          <a
            href="#location-form"
            className="bg-secondary-normal px-5 py-3 text-white cursor-pointer w-fit md:self-end hover:bg-secondary-dark"
          >
            Prendre rendez-vous
          </a>
        </div>
      </div>
      <div className="w-full bg-primary-dark flex items-center justify-center flex-col px-5 py-10">
        <img src={profile} alt="" className="w-48 h-48 rounded-full object-cover " />
        <div className="text-5xl text-secondary-normal mt-5 text-center">Reprenez votre santé en main !</div>
        <div className="max-w-3xl text-center mt-10 text-white">
          La <b className="text-secondary-light">naturopathie et la sophrologie</b> s'adressent à tous, hommes, femmes,
          ados, enfants dans une période délicate ou simplement parce que vous avez envie d'
          <b className="text-secondary-light">être acteur de votre santé et de votre équilibre émotionnel</b>.
          <br />
          <br />À votre <b className="text-secondary-light">écoute</b> sur le chemin de votre{" "}
          <b className="text-secondary-light">bien-être</b> au quotidien, j ai accompagné de{" "}
          <b className="text-secondary-light">nombreuses personnes</b> dans différentes problématiques :{" "}
          <b className="text-secondary-light">
            burn-out, insomnies, anxiété, intestin irritable, allergies, diabète, cholestérol, dysbiose, perte de poids,
            dévelopement personnel ...
          </b>{" "}
          Travaillant depuis <b className="text-secondary-light">20 ans</b> en pharmacie d'officine à Sète et ayant été
          accompagnant de personnes en soins palliatifs, j ai pu développer l'écoute et l 'empathie, avoir du recul sur
          les traitements allopathiques. Cela m'a aussi permis de mettre en pratique mes connaissances des différentes
          techniques en Naturopathie (
          <b className="text-secondary-light">
            réglage alimentaire, activité physique, gestion de stress, phyto et aromathérapie, oligothérapie,
            micronutrition, fluides, réflexes...
          </b>
          ) au service de personnes désireuses de prendre activement leur{" "}
          <b className="text-secondary-light">santé et confort en main</b>.
          <br />
          <br />
          <b className="text-secondary-light">Ensemble</b> nous avons pu établir des modifications de leur hygiène de
          vie quotidienne pour{" "}
          <b className="text-secondary-light">ramener l'organisme à un état d'équilibre et le maintenir</b>
        </div>
      </div>
      <div className="py-12 justify-center w-full bg-primary-light flex gap-2 text-center" id="location-form">
        <div className="w-1/2 text-white">
          La naturopathie et les pratiques qui y sont associées ne sont actuellement pas prises en charge par la
          Sécurité Sociale. Néanmoins,{" "}
          <a
            title="voir les mutuelles éligibles"
            className="text-secondary-light font-bold"
            href="https://www.syndicat-naturopathie.fr/les-mutuelles/"
          >
            de plus en plus de mutuelles (complémentaires santé) remboursent partiellement ou totalement ce type de
            consultations
          </a>
          <ul className="my-10">
            <li>
              Consultation individuelle: <b>50€</b>
            </li>
            <li>
              Consultation en collectif aux thermes de balaruc les bains: <b>13€</b>
            </li>
          </ul>
          Sur rendez vous du lundi au samedi de 9h a 19h au&nbsp;
          <a className="text-secondary-light font-bold" href="https://maps.app.goo.gl/iatM81hgGCgdWTnX7">
            35 avenue de montpellier, Balaruc les bains
          </a>
          <br />
          Consultation a distance possible ou à domicile
          <br />
          <br />
          Prise de rendez vous par &nbsp;
          <a className="text-secondary-light font-bold" href="mailto:carosete@free.fr">
            email &nbsp;
          </a>
          ou &nbsp;
          <a className="text-secondary-light font-bold" href="tel:+33783677554">
            téléphone
          </a>
        </div>
      </div>
      <div className="bg-white my-2 flex justify-center gap-2">
        <a href="https://www.instagram.com/naturopathe_sete/">
          <svg
            width="72px"
            className="-mt-1"
            height="72px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M3 11C3 7.22876 3 5.34315 4.17157 4.17157C5.34315 3 7.22876 3 11 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H11C7.22876 21 5.34315 21 4.17157 19.8284C3 18.6569 3 16.7712 3 13V11Z"
                stroke="#ae8d4a"
                stroke-width="2"
              ></path>{" "}
              <circle cx="16.5" cy="7.5" r="1.5" fill="#ae8d4a"></circle>{" "}
              <circle cx="12" cy="12" r="3" stroke="#ae8d4a" stroke-width="2"></circle>{" "}
            </g>
          </svg>
        </a>
        <a href="https://patreon.com/user?u=111778268&utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink">
          <svg
            width={60}
            className="p-2 mt-0.5 border-[6px] rounded-xl border-secondary-dark"
            height={60}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 1080 1080"
          >
            <path
              fill="#ae8d4a"
              d="M1033.05,324.45c-0.19-137.9-107.59-250.92-233.6-291.7c-156.48-50.64-362.86-43.3-512.28,27.2  C106.07,145.41,49.18,332.61,47.06,519.31c-1.74,153.5,13.58,557.79,241.62,560.67c169.44,2.15,194.67-216.18,273.07-321.33  c55.78-74.81,127.6-95.94,216.01-117.82C929.71,603.22,1033.27,483.3,1033.05,324.45z"
            />
          </svg>
        </a>

        <a href="https://www.linkedin.com/in/caroline-perrin-400002bb/">
          <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
                fill="#ae8d4a"
              ></path>{" "}
              <path
                d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z"
                fill="#ae8d4a"
              ></path>{" "}
              <path
                d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z"
                fill="#ae8d4a"
              ></path>{" "}
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z"
                fill="#ae8d4a"
              ></path>{" "}
            </g>
          </svg>
        </a>
      </div>
      <div className="text-secondary-dark text-center font-bold mb-2">
        <p>+33 7 83 67 75 54</p>
        <p>carosete@free.fr</p>
      </div>
    </div>
  );
};
